// /* CARD GENERAL */

// /* DEFAULT HOVER */
@mixin card-hover() {
	.card-text {
		background-color:$card-hover-bg-color;
		.event-date-wrapper {
			background-color:$event-date-hover-bg-color;
		}
	}	
}

// /* ALT-1 HOVER */
@mixin hover-alt-1() {
	.card-text {
		background-color:$alt-1-card-hover-bg-color;
		.event-date-wrapper {
			background:$alt-1-event-date-bg-hover-color;
		}
	}
}

// /* ALT-2 HOVER */
@mixin hover-alt-2() {
	.card-text {
		background-color:$alt-2-card-hover-bg-color;
	}
	.card-text,
	.card-text * {
		color:$alt-2-event-date-hover-text-color;
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color: $alt-2-event-date-hover-text-color;
		}
	}
	.event-date-wrapper {
		background:$alt-2-event-date-bg-hover-color;
		color: $alt-2-card-text-hover-color;
	}
}

// /* ALT-3 HOVER */
@mixin hover-alt-3() {
	.card-text {
		background-color:$alt-3-card-hover-bg-color;
		.event-date-wrapper {
			background:$alt-3-event-date-bg-hover-color;
		}
	}
}

// /* HIGHLIGHT HOVER */
@mixin hover-highlight() {
	.card-text {
		background-color:$highlight-card-hover-bg-color;
		.event-date-wrapper {
			background:$highlight-event-date-bg-hover-color;
		}
	}
}

// /* CARD BASE COLOUR OPTIONS */

@mixin card-light() {
	//border-color: $card-light-border-color;
	&.card-with-image {
		.card-image {
			margin:0 10px 0;
		}
		.card-text {
			background-color: transparent;
			position:relative;
			margin-top:-10px;
		} 
	}
		
	.card-text {
		background-color: $card-light-bg-color;
		.event-date-wrapper {
			background-color: $card-light-event-bg-color;
		}
	}
	
	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-light-title-color;
	}
	.card-text {
		color:$card-light-text-color;

		a {
			color:$card-light-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-light-title-color;
			}
			&:hover {
				color:$card-light-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-light-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-light-bg-color;
		}
	}

	.card-subtitle {
		color: $card-light-subtitle-color;
	}
}

@mixin card-alt-2() {
	&.card-with-image {
		.card-text {
			background-color: transparent;
		} 
	}
	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-alt-2-title-color;
	}
	.card-text {
		color:$card-alt-2-text-color;
		background-color: $card-alt-2-bg-color;
		.event-date-wrapper {
			background-color: $card-alt-2-event-bg-color;
		}

		a {
			color:$card-alt-2-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-alt-2-title-color;
			}
			&:hover {
				color:$card-alt-2-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-alt-2-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-alt-2-bg-color;
		}
	}

	.card-subtitle {
		color: $card-alt-2-subtitle-color;
	}
}

@mixin card-medium() {
	&.card-with-image {
		.card-text {
			background-color: transparent;
			color:white;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color: white;
			}
		} 
	}
	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-medium-title-color;
	}
	.card-text {
		color:$card-medium-text-color;
		background-color: $card-medium-bg-color;
		.event-date-wrapper {
			background-color: $card-medium-event-bg-color;
		}
		a {
			color:$card-medium-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-medium-title-color;
			}
			&:hover {
				color:$card-medium-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-medium-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-medium-bg-color;
		}
	}

	.card-subtitle {
		color: $card-medium-subtitle-color;
	}
}

@mixin card-dark() {
	&.card-with-image {
		.card-text {
			background-color: transparent;
		} 
	}

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-dark-title-color;
	}
	.card-text {
		color:$card-dark-text-color;
		background-color: $card-dark-bg-color;
		.event-date-wrapper {
			background-color: $card-dark-event-bg-color;
		}
		a {
			color:$card-dark-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-dark-title-color;
			}
			&:hover {
				color:$card-dark-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-dark-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-dark-bg-color;
		}
	}

	.card-subtitle {
		color: $card-dark-subtitle-color;
	}
}

@mixin card-highlight() {
	&.card-with-image {
		.card-text {
			background-color: transparent;
		}
	}

	h1, h2, h3, h4, h5, h6,
	.card-title,
	.card-title-et,
	.card-title-field {
		color: $card-highlight-title-color;
	}
	.card-text {
		color:$card-highlight-text-color;
		background-color: $card-highlight-bg-color;

		.event-date-wrapper {
			background-color: $card-highlight-event-bg-color;
		}
		
		a {
			color:$card-highlight-action-default;
			h1, h2, h3, h4, h5, h6,
			.card-title,
			.card-title-et,
			.card-title-field {
				color:$card-highlight-title-color;
			}
			&:hover {
				color:$card-highlight-action-hover;
				h1, h2, h3, h4, h5, h6,
				.card-title,
				.card-title-et,
				.card-title-field {
					color:$card-highlight-action-hover;
				}
			}
		}
	}

	.card-title /* card line-through */ {
		h2 span {
			background-color:$card-highlight-bg-color;
		}
	}

	.card-subtitle {
		color: $card-highlight-subtitle-color;
	}
}


// /* CARD MORE LINKS */
@mixin card-more-link-default() {
	a.card {
		background:$card-more-link-default-bg;
		color: $card-more-link-default-color;
		&:after {
			background: $card-more-link-default-icon;
		}
		&:hover {
			background:$card-more-link-default-hover-bg;
			color: $card-more-link-default-hover-color;
			&:after {
				background: $card-more-link-default-hover-icon;
			}
		}
	}
}

@mixin card-more-link-alt() {
	a.card {
		background:$card-more-link-alt-bg;
		color: $card-more-link-alt-color;
		&:after {
			background: $card-more-link-alt-icon;
		}
		&:hover {
			background:$card-more-link-alt-hover-bg;
			color: $card-more-link-alt-hover-color;
			&:after {
				background: $card-more-link-alt-hover-icon;
			}
		}
	}
}

@mixin card-more-link-alt-2() {
	a.card {
		background:$card-more-link-alt-2-bg;
		color: $card-more-link-alt-2-color;
		&:after {
			background: $card-more-link-alt-2-icon;
		}
		&:hover {
			background:$card-more-link-alt-2-hover-bg;
			color: $card-more-link-alt-2-hover-color;
			&:after {
				background: $card-more-link-alt-2-hover-icon;
			}
		}
	}
}


// /* CARD FEED MORE LINKS */
@mixin card-feed-link-default() {
	a {
		background:$card-feed-link-default-bg;
		color: $card-feed-link-default-color;
		// &:after {
		// 	background: $card-feed-link-default-icon;
		// }
		&:hover {
			background:$card-feed-link-default-hover-bg;
			color: $card-feed-link-default-hover-color;
			// &:after {
			// 	background: $card-feed-link-default-hover-icon;
			// }
		}
	}
}

@mixin card-feed-link-alt() {
	a {
		background:$card-feed-link-alt-bg;
		color: $card-feed-link-alt-color;
		// &:after {
		// 	background: $card-feed-link-alt-icon;
		// }
		&:hover {
			background:$card-feed-link-alt-hover-bg;
			color: $card-feed-link-alt-hover-color;
			// &:after {
			// 	background: $card-feed-link-alt-hover-icon;
			// }
		}
	}
}


// /* EVENT CARD SPECIFIC */ //

.row-max-2,
.row-max-1,
.side-row {
	.card-with-image .event-date-wrapper {
    margin-top: 0px;
	}
}
.side-row .node-event a.card.card-with-image .card-text {
	background-color: $card-light-bg-color;
}
.section-row {
	&.palette-default .node-event a.card.card-with-image .card-text {
		background-color: $card-light-bg-color;
	}
	&.palette-alt-1 .node-event a.card.card-with-image .card-text {
		background-color: $card-alt-2-bg-color;
	}
	&.palette-alt-2 .node-event a.card.card-with-image .card-text {
		background-color: $card-medium-bg-color;
		color:$card-medium-text-color;
		h1, h2, h3, h4, h5, h6,
		.card-title,
		.card-title-et,
		.card-title-field {
			color: $card-medium-title-color;
		}
	}
	&.palette-alt-3 .node-event a.card.card-with-image .card-text {
		background-color: $card-dark-bg-color;
	}
	&.palette-highlight .node-event a.card.card-with-image .card-text {
	background-color: $card-highlight-bg-color;
}
}