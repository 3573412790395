// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-title-font-family		: $title-font-family;
$card-title-font        	: bold 1.111rem/1.22222222222222 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: bold 1.111rem/1.2 $title-font-family;
$card-feed-link-font			: bold 1.111rem/1.2 $title-font-family;


$card-shadow                   : $box-shadow;
$card-border                   : none;
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : lighten($platinum,10%);
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $secondary;
$card-date-font                : 0.8125em/1 $base-font-family;


// /* --- HOVER STYLES --- */

// DEFAULT / LIGHT
$card-hover-bg-color           : $blue_tsl;
$card-hover-border             : none;
$card-hover-shadow             : none;
$card-action-hover             : $black_olive; //colour of card title text when you hover over it
$card-text-hover-color         : $black_olive; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $black_olive;
$card-date-hover-bg-color      : transparent; // allows display dates (e.g. events) to have different hover background from the rest of the card
$event-date-hover-text-color   : $black_olive;
$event-date-hover-bg-color     : $blue_tsl;

//ALT 1 / LIGHT 2
$alt-1-card-hover-bg-color            : $green_tsl;
$alt-1-event-date-bg-hover-color      : $green_tsl;

//ALT 2 / MEDIUM
$alt-2-card-hover-bg-color            : $green_tsl;
$alt-2-event-date-bg-hover-color      : $green_tsl;
$alt-2-event-date-hover-text-color    : $white;
$alt-2-card-text-hover-color          : $white;

//ALT 3 / DARK
$alt-3-card-hover-bg-color            : $yellow_tsl;
$alt-3-event-date-bg-hover-color      : $yellow_tsl;
//$alt-3-event-date-hover-text-color    : $black_olive;

//HIGHLIGHT / HIGHLIGHT
$highlight-card-hover-bg-color        : $white_tsl;
$highlight-event-date-bg-hover-color  : $yellow_tsl;
//$highlight-event-date-hover-text-color: $black_olive;


/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- uncomment the appropriate line in base/_cards.scss as appropriate
// ----- uncomment the appropriate line in mixins/_mixins.scss to include the _card-hidden-summary.scss mixin functinality
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : $white_smoke;
$card-light-border-color       : transparent;
$card-light-title-color        : $secondary;
$card-light-action-default     : $action-default;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $granite_grey;
$card-light-subtitle-color     : $granite_grey;
$card-light-event-text-color   : $granite_grey;
$card-light-event-bg-color     : $yellow_tsl;

// /* --- CARD-LIGHT-2 -  */
$card-alt-2-bg-color           : $white_smoke;
$card-alt-2-border-color       : transparent;
$card-alt-2-title-color        : $secondary;
$card-alt-2-action-default     : $action-default;
$card-alt-2-action-hover       : $action-hover;
$card-alt-2-text-color         : $granite_grey;
$card-alt-2-subtitle-color     : $granite_grey;
$card-alt-2-event-text-color   : $granite_grey;
$card-alt-2-event-bg-color     : $yellow_tsl;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $white_tsl;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $secondary;
$card-medium-action-default    : $action-default;
$card-medium-action-hover      : $action-hover;
$card-medium-text-color        : $granite_grey;
$card-medium-subtitle-color    : $granite_grey;
$card-medium-event-text-color  : $granite_grey;
$card-medium-event-bg-color    : $yellow_tsl;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $green_tsl;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $primary;
$card-dark-action-default      : $action-default;
$card-dark-action-hover        : $action-hover;
$card-dark-text-color          : $granite_grey;
$card-dark-subtitle-color      : $granite_grey;
$card-dark-event-text-color    : $granite_grey;
$card-dark-event-bg-color      : $green_tsl;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $yellow_orange;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : $secondary;
$card-highlight-action-default : $action-default;
$card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : $granite_grey;
$card-highlight-subtitle-color : $granite_grey;
$card-highlight-event-text-color   : $granite_grey;
$card-highlight-event-bg-color   : $rajah;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $white_smoke;
$card-more-link-default-color       : $secondary;
$card-more-link-default-icon        : $arrow-olive-right no-repeat 100% 100% / 20px 20px;
$card-more-link-default-hover-bg    : $blue_tsl;
$card-more-link-default-hover-color : $secondary;
$card-more-link-default-hover-icon  : $arrow-olive-right no-repeat 100% 100% / 20px 20px;

$card-more-link-alt-bg              : $yellow_orange;
$card-more-link-alt-color           : $secondary;
$card-more-link-alt-icon            : $arrow-olive-right no-repeat 100% 100% / 20px 20px;
$card-more-link-alt-hover-bg        : $white_tsl;
$card-more-link-alt-hover-color     : $secondary;
$card-more-link-alt-hover-icon      : $arrow-olive-right no-repeat 100% 100% / 20px 20px;

$card-more-link-alt-2-bg              : $green_tsl;
$card-more-link-alt-2-color           : $secondary;
$card-more-link-alt-2-icon            : $arrow-olive-right no-repeat 100% 100% / 20px 20px;
$card-more-link-alt-2-hover-bg        : $yellow_tsl;
$card-more-link-alt-2-hover-color     : $secondary;
$card-more-link-alt-2-hover-icon      : $arrow-olive-right no-repeat 100% 100% / 20px 20px;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : $button-yellow no-repeat 50% 50% / 109px 46px;
$card-feed-link-default-color       : $granite_grey;
//$card-feed-link-default-icon        : $action-default $arrow-white-right no-repeat scroll 50% 50% / 7px 10px; //background for the default :after component
$card-feed-link-default-hover-bg    : $button-sea no-repeat 50% 50% / 109px 46px;
$card-feed-link-default-hover-color : white;
//$card-feed-link-default-hover-icon  : $action-hover $arrow-white-right no-repeat scroll 50% 50% / 7px 10px;

$card-feed-link-alt-bg              : $button-sea no-repeat 50% 50% / 109px 46px; // background for the entire link
$card-feed-link-alt-color           : white;
//$card-feed-link-alt-icon            : $white $arrow-blue-right no-repeat scroll 50% 50% / 7px 10px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : $button-emerald no-repeat 50% 50% / 109px 46px;
$card-feed-link-alt-hover-color     : white;
//$card-feed-link-alt-hover-icon      : $action-hover $arrow-white-right no-repeat scroll 50% 50% / 7px 10px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $action-default;
$button-link-default-border-color       : $action-default;
$button-link-default-color              : white;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $action-hover;
$button-link-default-hover-border-color : $action-hover;
$button-link-default-hover-color        : none;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $grey;
$button-link-alt-border-color           : $grey;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $action-hover;
$button-link-alt-hover-border-color     : $action-hover;
$button-link-alt-hover-color            : white;
$button-link-alt-hover-icon             : none;