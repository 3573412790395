@import url(https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700|Open+Sans:300,400,700);

$base-font-family      		: 'Open Sans', sans-serif;
$title-font-family    		: 'Nanum Gothic', sans-serif;
$subtitle-font-family 	 	: 'Nanum Gothic', sans-serif;

$base-font-size						: 18px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$button-link-font 				: 600 $base-font-size/1 $title-font-family;

/* FORMS */
$button-font 							: 600 $base-font-size/1 $title-font-family;