button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a {
	@include button-link-default();
  border-radius: $button-border-radius;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.views-reset-button input[type="submit"] {
  background:$black_olive;
  background: linear-gradient(to bottom right,#7F8081 0%,#7F8081 50%,#5F6062 50%);
  border-radius: 10px 0 10px 0;
  &:hover,
  &:focus {
    background: $emerald;
    background: linear-gradient(to bottom right,#43A592 0%,#43A592 50%,#148E77 50%);
  }
}

.views-submit-button input[type="submit"] {
  background: $yellow_orange;
  background: linear-gradient(to bottom right,#FCC163 0%,#FCC163 50%,#FBB13C 50%);
  border-radius: 10px 0 10px 0;
  &:hover,
  &:focus {
    background:$light_seagreen;
    background: linear-gradient(to bottom right,#48C9B0 0%,#48C9B0 50%,#1ABB9C 50%);
  }
}