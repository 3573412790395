#site-footer {
  .outer-wrapper {
    padding: 0 $horz-space/2;
  }

  h2 {
    color: $footer-first-text;
  }

  .item-list ul:not(.slick-slider) {
    text-align: center;
  }
  ul:not(.contextual-links) {
    padding: 0;
    margin: 0 0 10px;

    li {
      list-style: none;
      line-height: 1.5em;
    }
  }

  .card-follow-us-on ul li {
    display: inline-block;
  }

  .footer-first {
    background: $footer-first-bg repeat $primary;
    color: $footer-first-text;
    padding: $vert-space 0;
    @include palette-footer-first-slick-controls;

    .outer-wrapper {
      border-bottom: $footer-first-border;
      padding-bottom: $vert-space/2;
    }

    .card {
      @include palette-footer-first-cards;
      .card-logo {background:transparent;}
    }
    a.card:hover {
      @include card-hover;
    }
    .card-more-link {
      @include palette-footer-first-card-more-link;
    }
    .card-feed-link {
      @include palette-footer-first-card-feed-link;
    }

    a {
      color: $footer-first-action;
      &:hover {
        color: $footer-first-action-hover;
      }
    }
  }
  .footer-second {
    background: $footer-second-bg;
    color: $footer-second-text;
    padding: $vert-space 0;
    @include palette-footer-second-slick-controls;

    a {
      color: $footer-second-action;
      &:hover {
        color: $footer-second-action-hover;
      }
    }
    .card {
      @include palette-footer-second-cards;
    }
    a.card:hover {
      @include card-hover;
    }
    .card-more-link {
      @include palette-footer-second-card-more-link;
    }
    .card-feed-link {
      @include palette-footer-second-card-feed-link;
    }

    section.block {
      margin-bottom: $vert-space;
      @media screen and (min-width: 45em) {
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .footer-block {
      margin-bottom: $vert-space*2;

      @media screen and (min-width: 45em) {
        margin-bottom: 0;
      }
    }

  }
  .footer-third {
    background: $footer-third-bg;
    color: $footer-third-text;
    padding: $vert-space/2 0;
    text-align: center;
    font-size: 0.875em;

    a {
      color: $footer-third-text;
      &:hover {
        color: $footer-third-action-hover;
      }
    }
    #manta-ray-media {
      float: none;
      @include media(880px) {
        float: right;
      }
      a {
        color: $action-default;
        &:hover {
          color: $action-hover;
        }
      }
    }
    .block-menu {
      float: none;
      @include media(880px) {
        float: left;
      }
      ul {
        li {
          display: inline-block;
          margin-right: 5px;
          padding-right: 10px;
          border-right: 1px solid $secondary-border-color;
        }
        li.last {
          border-right: none;
          margin-right: 0;
          padding-right: 0;
        }
      }
    }
  }

  .card-links {
    display: inline-block;
    margin: 0 10px 10px 0;
    text-indent: -9999px;
    a {
      display: block;
      margin: 0;
      height: 40px;
      width: 40px;
      background: $ico-web 50% 50% / 19px 19px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-web 50% 50% / 19px 19px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* newsletter */
    .newsletter a,
    a[href^="http://eepurl.com"] {
      background: $ico-newsletter 50% 50% / 19px 18px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-newsletter 50% 50% / 19px 18px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* linkedin */
    .linkedin a,
    a[href^="https://www.linkedin.com"],
    a[href^="http://www.linkedin.com"],
    a[href^="https://linkedin.com"],
    a[href^="http://linkedin.com"] {
      background: $ico-linkedin 50% 50% / 19px 18px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-linkedin 50% 50% / 19px 18px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* twitter */
    .twitter a,
    a[href^="https://www.twitter.com"],
    a[href^="http://www.twitter.com"],
    a[href^="https://twitter.com"],
    a[href^="http://twitter.com"] {
      background: $ico-twitter 50% 50% / 20px 20px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-twitter 50% 50% / 20px 20px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* facebook */
    .facebook a,
    a[href^="https://www.facebook.com"],
    a[href^="http://www.facebook.com"],
    a[href^="https://facebook.com"],
    a[href^="http://facebook.com"] {
      background: $ico-facebook 50% 50% / 19px 18px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-facebook 50% 50% / 19px 18px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* google+ */
    .g-plus a,
    a[href^="https://www.plus.google.com"],
    a[href^="http://www.plus.google.com"],
    a[href^="https://plus.google.com"],
    a[href^="http://plus.google.com"] {
      background: $ico-gplus 50% 50% / 19px 18px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-gplus 50% 50% / 19px 18px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* Institutional profile */
    .institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution 50% 50% / 19px 19px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-institution 50% 50% / 19px 19px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* Research Gate */
    .research-gate a,
    a[href*="researchgate.net"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
      background: $ico-researchgate 50% 50% / 19px 19px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-researchgate 50% 50% / 19px 19px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* Google Scholar */
    .g-scholar a,
    a[href*="scholar.google"] {
      background: $ico-gscholar 50% 50% / 17px 19px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-gscholar 50% 50% / 17px 19px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* orcid */
    .orcid a,
    a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background: $ico-orcid 50% 50% / 18px 18px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-orcid 50% 50% / 18px 18px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* YouTube */
    .youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background: $ico-youtube 50% 50% / 19px 13px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-youtube 50% 50% / 19px 13px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* flickr */
    .flickr a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background: $ico-flickr 50% 50% / 18px 14px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-flickr 50% 50% / 18px 14px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }

    /* instagram */
    .instagram a,
    a[href^="https://www.instagram.com"],
    a[href^="http://www.instagram.com"],
    a[href^="https://instagram.com"],
    a[href^="http://instagram.com"] {
      background: $ico-instagram 50% 50% / 18px 18px no-repeat, $leaf-emerald-right 50% 50% / 40px 40px no-repeat;
      &:hover {
        background: $ico-instagram 50% 50% / 18px 18px no-repeat, $leaf-yellow-right 50% 50% / 40px 40px no-repeat;
      }
    }
  }

  .copyright {
    padding-left: 1em;
  }

  .utility-menu {
    ul {
      text-align: right;
      font-weight: 600;

      li {
        display: inline;
        padding-right: 1em;

        a {
          color: $footer-first-action;
        }
      }
    }
  }
}
