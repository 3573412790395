// /* HEADER */ //

$header-menu-font 				: /*0.875rem*/ 0.778rem/1 $base-font-family;
$header-search-field-font : 0.875rem/1.286 $base-font-family;

$header-bg-color                   : none;

$header-menu-link-color            : $primary;
$header-menu-link-hover            : $black;

$header-search-field-bg-color      : $pale_grey;
$header-search-field-text-color    : $primary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;