section .section-row.section-with-promo {
  padding: 0;
  width: 100%;
  position: relative;
  line-height: 0;
  .section-inner {
    max-width: none;
    width: 100%;
  }
}
.view-promo {
  height: 300px;
  margin: -20px -30px;
  .promo {
    width: 100%;
    .card-image { width: 100%; }
    picture, img {
      max-width: none;
      width: 100%;
      display: inline-block;
      vertical-align: baseline;
    }
  }
  .card-promo-text { position: absolute; }
}