/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;

	.card-qa-answer {
		display: none;
		padding: 10px 10px 10px 40px; 	
		box-sizing: border-box;
 
		:last-child {
			margin-bottom: 0;
		}
	}
	.faq-question {
    padding-left: 10px;
	}
	.card-qa-question {
		text-decoration: none;
		color: $light_seagreen;
		font-size: 1.111em;
		padding: 10px 0 10px 40px;
		line-height: 20px;
		display: block;
		margin-bottom: -1px;
		cursor: pointer;
		box-sizing: border-box;
		background: $ico-expand-sea no-repeat scroll 0 50% / 24px 24px transparent;
		&:hover {
			color:$yellow_orange;
			background: $ico-expand-yellow no-repeat scroll 0 50% / 24px 24px transparent;
		}
		&.active {
			background: $ico-collapse-sea no-repeat scroll 0 50% / 24px 24px transparent;
			&:hover {
				color:$yellow_orange;
				background: $ico-collapse-yellow no-repeat scroll 0 50% / 24px 24px transparent;
			}
		}
	}
}

.section-row {
	&.palette-highlight {
		.view-faqs {
			.card-qa-answer {
				color:white;
			}
			.card-qa-question {
				color: $light_seagreen;
				background: $ico-expand-sea no-repeat scroll 0 50% / 24px 24px transparent;
				&:hover {
					color:white;
					background: $ico-expand-white no-repeat scroll 0 50% / 24px 24px transparent;
				}
				&.active {
					background: $ico-collapse-sea no-repeat scroll 0 50% / 24px 24px transparent;
					&:hover {
						background: $ico-collapse-white no-repeat scroll 0 50% / 24px 24px transparent;
					}
				}
			}
		}
	}
	&.palette-alt-2 {
		.view-faqs {
			.card-qa-answer {
				color:white;
			}
		}
	}
}