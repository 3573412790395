.view-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
	.views-row {
		padding: $vert-space/2 0;
	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			//float:left;
			display:inline-block;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			.file-extension {
			 	padding: 5px 10px;
				text-transform:lowercase;
				font-size:1.111em;
        line-height:1;
				text-align:center;
				font-weight:bold;
				display:inline;
				color:white;
			}
			.file-size {
        padding: 7px 10px 6px;
				font-size:0.777em;			
				text-align:center;
				color:white;
        border:$secondary-border;
        border-top:none;
				display:inline;
			}
		}
		
		.file-name {
			overflow:hidden;
			background: $white_smoke;
			padding: 11px 20px 15px;
			margin-top: 4px;
			font-family: $title-font-family;
			font-weight: bold;
			color:$secondary;
		}
		&:hover {
			.file-name {
				background: $soap;
				color:$secondary;
			}
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_color;
    }
		.file-size {
			background:$docx_size_color;
		}
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_color;
    }
		.file-size {
			background:$xlsx_size_color;
		}
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_color;
    }
		.file-size {
			background:$pptx_size_color;
		}
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_color;
    }
		.file-size {
			background:$pdf_size_color;
		}
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_color;
    }
		.file-size {
			background:$txt_size_color;
		}
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_color;
    }
		.file-size {
			background:$jpg_size_color;
		}
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_color;
    }
		.file-size {
			background:$zip_size_color;
		}
  }
} 

.section-row {
	&.palette-highlight {
		.file-name {
			background: $yellow_orange;
		}
		&:hover {
			.file-name {
				background: $white_tsl;
			}
		}
	}
	&.palette-alt-1 {
		&:hover {
			.file-name {
				background: $green_tsl;
			}
		}
	}
	&.palette-alt-2 {
		.file-name {
			background: $white_tsl;
		}
		&:hover {
			.file-name {
				background: $green_tsl;
				color:white;
			}
		}
	}
	&.palette-alt-3 {
		.file-name {
			background: $green_tsl;
		}
		&:hover {
			.file-name {
				background: $yellow_tsl;
			}
		}
	}
}

// RESPONSIVE STYLING

.view-attachments {
	.views-row {
		width:100%;
		float:left;

		@media (min-width:720px) {
			width:47%;
			margin-right:15px;
		}
		
		&:nth-child(2n+1){
			@media (min-width:720px) and (max-width:1199px) {
				clear:left;
			}
		}

		@media (min-width:1200px) {
			width:23%;
			margin-right:15px;
		}
		&:nth-child(4n+1){
		 	@media (min-width:1200px) {
				clear:left;
			}
		}
	}
}

.side-row .view-attachments .views-row {
	width:100%;
}

.one-sidebar,
.two-sidebars {
	.content-main,
	.content-additional {
		.view-attachments	.views-row {
			width:100%;
			@media (min-width:960px) {
				width:48%;
				margin-right:15px;
				&:nth-child(2n+1) {
					clear:left;
				}
				&:nth-child(2n) {
					margin-right:0;
				}
			}
		}
	}
}

