.page-search-node {
	section#content {
		background: none;
		.search-advanced {
			margin-top: $vert-space;
		}
		.search-result {
			padding: $vert-space $horz-space;
		}
	}
}
