// Set consistent vertical and horizontal spacing units.

$vert-space     : 20px;
$horz-space     : 20px;

$grids: 12;
$gutters: 1/3;

$cols16: 16;
$cols24: 24;
$mobile         : 0em;
$mobile-columns : 12;
$narrow         : 45em;
$narrow-columns : 12;
$normal         : 60em;
$normal-columns : 12;
$wide           : 75em;
$wide-columns   : 12;

$site-max-width : 1600px; //sometimes 1320px