// GENERAL / RESET STYLES

//.front .page > main { display:none; }

.section-row.section-with-slider { padding:0; }

.section-row.section-with-slider .section-inner {
	margin: 0 auto;
	max-width:$site-max-width;
	padding:0;
	position: relative;
}

.slick-slide { margin-left:10px; margin-right:10px; }

// /* COMMON CONTROL/NAV STYLES */
.slick-controls, .slick-nav {
	button.slick-arrow {
		bottom:80px;
		top:auto;
		z-index:10;
		text-indent:-999em;
		font-family:$base-font-family;
		overflow:hidden;
		padding: 20px;
		height:48px;
		width:48px;
		@include media($narrow) {
			bottom:17px;
		}
		&:before {
			content:"";
		}
		&.slick-prev {
			left:0;
			background-size:contain;
			&:after {
				display:inline-block;
				height:20px;
				width:20px;
				content:"";
			}
		}
		&.slick-next {
			right:20px;
			background-size:contain;
			@media(min-width:720px) {
				right:1.25rem;
			}
			&:after {
				display:inline-block;
				height:20px;
				width:20px;
				content:"";
			}
			@include media($narrow) {
				right:0;
			}
		}
	}
	.slick-dots {
		padding:0 20px;
		li {
			width:14px;
			height:14px;
			margin:5px;
			button {
				display:inline-block;
				width:6px;
				height:6px;
				border-radius:50%;
				&:before {
					content:"";
					display:none;
				}
			}
		}
	}	
}

// /* SLICK CONTROLS ONLY -- everywhere but slides view which uses .slick-nav class instead
.slick-controls {
	height:56px;
	position:relative;
	margin-top:20px;
	
	@include media($narrow) {
		display:inline-block;
	}

	button.slick-arrow {
		bottom:-17px;
		padding: 15px;
	}
	
	.slick-dots {
		bottom:17px;
	}	
}

.section-row {
	.slick-controls {
		@include media($narrow) {
			width:50%;
		}
		@include media($normal) {
			width:33.333%;
		}
	}
}
.one-sidebar {
	.content-main,
	.content-additional {
		.section-row {
			.slick-controls {
				@include media($narrow) {
					width:100%;
				}
				@include media($normal) {
					width:50%;
				}
			}
		}
	}
}

.side-row {
	.slick-controls {
		width: 100%;
		
		.slick-dots {
			@include media($narrow) {
				display: none !important;
			}
			@include media($wide) {
				display: block !important;
			}
		}
	}
	
	.view-twitter .slick-controls .slick-dots {
		@include media($narrow) {
			display: none !important;
		}
		@include media(1450px) {
			display: block !important;
		}
	}
}

.footer-first {
	.section-row {
		.slick-controls {
			width:100%
		}
	}
}

#site-footer .item-list ul:not(.slick-slider) li {
	padding: 0 10px;
}



// /* SLICK NAV ONLY
.slick-nav {
	height: 50px;
	&:empty { display: none; }
	button.slick-arrow {
		padding: 15px;
	}
	.slick-dots {
		bottom:14px;
	}	
}




// SLIDER VIEWS
.view-slider {
	position:relative;
	.view-content {
		margin:0;
		.slick-list {
			overflow:visible;
			.slick-track {
				background-color:$slide-text-bg-color;
				
				@include media($narrow) {
					background-color: transparent;
				}
				
				.views-row {
					margin: 0;

					.card-slide-image {
						position:relative;
						background:$light_seagreen;
						@media (min-width:720px) {
							&:after {
								background:$slider-background;
								height:608px;
								width:647px;
								display:inline-block;
								content:"";
								position: absolute;
								top: -2px;
								right: -15px;
							}
						}
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
								//min-height: 600px;
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}
						}
					}
					
					.card-slide-description {
						bottom:0;
						position:relative;
						//background-color:$slide-text-bg-color;
						font:$slide-text-font;
						padding: 60px 20px 20px 20px;
						margin:0;
						color:$slide-text-color;
						min-height:66px;
					
						p {
							font:$slide-text-font;
							margin: 0;
						}
						
						&:after {
							background:rgba(251,177,60,0.6);
							width:100%;
							height:20px;
							display:block;
							content:"";
							position: absolute;
    					top: -20px;
    					left: 0;
						}
						@include media($narrow) {
							max-width:340px;
							height:144px;
							//background-color:$slide-text-bg-color;
							position:absolute;
							padding:20px 0 0;
							right:60px;
							top:80px;
							text-align:right;
							&:after {
								display:none;
							}
						}
					}
				}
			}
		}
		
		.card-edit-node a {
			@include button-states($button-bg, $button-text, $button-border-color);
			font-size: 0.875rem;
			font-weight: bold;
			padding: 5px 15px;
			position: absolute;
			left: 0;
			text-transform: uppercase;
			top: 0;
		}
	}
	// SLIDER NAVIGATION
	.view-footer {
		position:absolute;
		bottom:0;
		width:100%;
		//left:0;
		@include media($narrow) {
			width:340px;
			right:60px;
			bottom:calc(100% - 80px);
		}
	}
}

.section-row.palette-highlight .view-slider .view-content .slick-list .slick-track .views-row {
	.card-slide-image:after {
		background:$slider-background-yellow;
	}
	.card-slide-description {
		color:$gun_metal;
	}
}

.section-row .view-slider .view-content .slick-list .slick-track .views-row {
	@include media($narrow) {
		margin-left: 10px;
		margin-right: 10px;
	}
}

// FULL WIDTH SLIDERS
// ONLY AT HOME PAGE ALL REGIONS AND OTHER PAGES ON THE HERO

.front,
.not-front .content-hero {
	.section-row.section-with-slider .section-inner {
		margin: 0 auto;
		max-width:100%; //$site-max-width;
		padding:0;
		position: relative;

		@media (min-width:1600px) {
			//max-height: 450px;
			overflow: hidden;

			.view-slider .view-content .slick-list .slick-track .views-row {
				margin: 0;

				.card-image {
					.card-slide-image {
						position:relative;
						img {
							max-width:none;
							position:relative;
							width: 100%;

							@include media($narrow) {
								left: -10px;
								width: calc(100% + 20px);
							}

							@include media($wide) {
								left:calc(50% - 800px);
								width: auto;
							}							

							@media (min-width:1600px) {
								left:0;
								width: 100%;
							}
						}
					}
				}
				
				.card-slide-description {
					bottom:0;
					position:relative;
					background-color:$slide-text-bg-color;
					font:$slide-text-font;
					padding:17px 20px 55px 20px;
					margin:0;
					//color:$slide-text-color;
					min-height:66px;

					@include media($narrow) {
						// background-color:$slide-text-bg-color;
						// position:absolute;
						// padding:17px 20px;
						// max-width:75%;
						max-width:340px;
						height:144px;
						position:absolute;
						padding:20px 0 0;
						right:60px;
						top:80px;
						text-align:right;
					}
					// @include media(1600px) { 
					// 	top: calc(450px - 66px);
					// 	bottom:auto;
					// 	left: calc(50% - 800px + 20px);
					// 	max-width:50%;
					// }
				}
			}
		}

		.view-slider .slick-slider:not(.slick-dotted) {
			.card-description-inner.card-description-empty {
				display: none;
			}
		}
		button.slick-arrow {
			@include media($narrow) {
				bottom:-30px;
			}
		}

		// .view-slider .view-footer {
		// 	@include media(1600px) {
		// 		top: calc(450px - 117px);
		// 		bottom:auto;
		// 		left: calc(50% - 800px + 20px);
		// 		max-width: calc(1600px - 40px);
		// 	}
		// }
	}
}


// SLIDERS HINDERED BY SIDEBARS - image
.one-sidebar,
.sidebar-second {
	.content-main,
	.content-additional,
	.sidebar {
		.view-slider {
			.view-content .slick-list .slick-track .views-row {
				.card-slide-image {
					img {
						left: auto;
						width: 100%;
						max-width: 100%; 
					}
					&:after {
						display:none;
					}
				}
				.card-slide-description {
					bottom:0;
					position: relative;
					width:100%;
					padding:20px;
					text-align: left;
					top:auto;
					right:auto;
					max-width: 100%;
					color:$secondary;
					font-size: 1.111em;
				}
			}
			.view-footer {
				bottom: 0;
				position:relative;
				width:100%;
				left: auto;
				right:auto;
				button.slick-arrow {
					&.slick-next {
						right:1.25rem;
					}
					&.slick-prev {
						left:1.25rem;
					}
				}
				ul.slick-dots {
					bottom:50px;
				}
			}
		}
	}
}


// PHOTO GALLERY
.card-photos {
	position:relative;
	.photoswipe-gallery {
		margin:0;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
	}
	
	// GALLERY CONTROLS
	.view-footer {
		text-align:center;
	}
}


// SLIDER / TWITTER
.view-type-slider, .view-twitter {
	position:relative;
	.view-content {
		margin:0;
		position: relative;
		.slick-slider {
			margin-bottom:0;
			.slick-list {
				.views-row {
					.card-slide-image {
						position:relative;
						height:450px;
						img {
							max-width:none;
							position:absolute;
							left:50%;
							margin-left:-800px;
						}
					}
					.card-slide-description {
						width:100%;
						bottom:0;
						position:absolute;
						background-color:rgba(35,31,32,0.8);
						padding:17px 20px 51px 20px;
						@include media($narrow) {
							padding:17px 340px 17px 20px;
						}
						font-size:1.125rem;
						color:white;
						line-height:1.225;
					}
				}
			}
		}
		
	}
	.view-footer {
		text-align:center;
	}
}

// FULL WIDTH SPECIFIC GODDAMNIT CAT WHY YOU DO THIS TO ME

.content-hero,
.postscript-first {
	.section-row {
		.view-id-slider .slick-nav { 
			button.slick-arrow {
				&.slick-next,
				&.slick-prev {
		    	background: none;
					
					&:hover {
						background: none;
					}
				}
			}
			.slick-dots {
				bottom:110px;
				padding: 0 20px 0 35px;
				@media(min-width:720px) {
					bottom:4px;
				}
			}
		}
		&.palette-highlight {
			.view-id-slider .slick-nav {
				button.slick-arrow {
					&.slick-next {
						&:after {
							background:$arrow-olive-right no-repeat scroll 50% 50% / 20px 20px;
						}
						&:hover:after {
							background:$arrow-white-right no-repeat scroll 50% 50% / 20px 20px;
						}
					}
					&.slick-prev {
						&:after {
							background:$arrow-olive-left no-repeat scroll 50% 50% / 20px 20px;
						}
						&:hover:after {
							background:$arrow-white-left no-repeat scroll 50% 50% / 20px 20px;
						}
					}
				}
				li {
					button {
						border:2px solid $black_olive;
						&:hover {
							border:2px solid white;
							background:white;
						}
					}
					&.slick-active button {
						background-color:$black_olive;
						border:2px solid $black_olive;
						&:hover {
							border:2px solid white;
							background:white;							
						}
					}
				}
			}
		}
		&.palette-default,
		&.palette-alt-1,
		&.palette-alt-2,
		&.palette-alt-3, {
			.view-id-slider .slick-nav {
				button.slick-arrow {
					&.slick-next {
						&:after {
							background:$arrow-white-right no-repeat scroll 50% 50% / 20px 20px;
						}
						&:hover:after {
							background:$arrow-olive-right no-repeat scroll 50% 50% / 20px;
						}
					}
					&.slick-prev {
						&:after {
							background:$arrow-white-left no-repeat scroll 50% 50% / 20px 20px;
						}
						&:hover:after {
							background:$arrow-olive-left no-repeat scroll 50% 50% / 20px 20px;
						}
					}
				}
				li {
					button {
						border:2px solid white;
						&:hover {
							border:2px solid $black_olive;
							background:$black_olive;
						}
					}
					&.slick-active button {
						background-color:$yellow_orange;
						border:2px solid $yellow_orange;
						&:hover {
							border:2px solid $black_olive;
							background:$black_olive;							
						}
					}
				}
			}
		}
	}
}



