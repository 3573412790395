// /* MAIN MENU */ //

$nav-base-font        	 	: 1.222rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 1rem/1.375 $title-font-family;
$nav-sub-base-font        : 1rem/1.3 $base-font-family;

$nav-wrapper-bg-color					: transparent;
$nav-base-bg-color        		: white; 
$nav-base-text-color     		 	: $primary;
$nav-base-padding        			: 1rem 1.111rem 1.778rem 1.111rem;
$nav-base-hover-padding   		: 1rem 1.111rem 1.778rem 1.111rem;
$nav-sub-base-padding					: 0.667rem 1.111rem;
$nav-sub-base-hover-padding		: 0.667rem 1.111rem;

$nav-base-padding-smaller 				: 0.375rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.375rem 1.125rem;

$nav-active-bg-color      		: $menu_green_tsl;
$nav-active-text-color    		: $primary;
$nav-active-border-radius 		: 0; 

$nav-hover-bg-color       		: $menu_yellow_tsl;
$nav-hover-text-color     		: $primary;
$nav-hover-border	  	    		: none;

$nav-highlight-bg-color   		: $menu_yellow_tsl;
$nav-highlight-text-color 		: $primary; 
$nav-highlight-border	    		: none;

$nav-sub-bg-color         		: white;
$nav-sub-hover-bg-color   		: $yellow_orange;
$nav-sub-text-color       		: $secondary;
$nav-sub-hover-text-color   	: $secondary;
$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

$nav-mob-text-color      						: $primary;
$nav-mob-active-text-color      		: $blue_yonder;
$nav-mob-active-bg-color      			: white;

$nav-mob-1st-bg-color     					: white;
$nav-mob-1st-highlight-bg-color     : white;
$nav-mob-1st-highlight-text-color   : $primary;
$nav-mob-1st-line-color     				: red;
$nav-mob-1st-border-color     			: $glitter;

$nav-mob-2nd-bg-color     					: $white_smoke;
$nav-mob-2nd-highlight-bg-color     : $white_smoke;
$nav-mob-2nd-highlight-text-color   : $secondary;
$nav-mob-2nd-line-color     				: $glitter;
$nav-mob-2nd-border-color     			: $glitter;

$nav-mob-3rd-bg-color     					: $glitter;
$nav-mob-3rd-highlight-bg-color     : $glitter;
$nav-mob-3rd-highlight-text-color   : $blue_yonder;
$nav-mob-3rd-line-color     				: $columbia_blue;
$nav-mob-3rd-border-color     			: $columbia_blue;

$nav-mob-4th-bg-color     					: $azureish_white;
$nav-mob-4th-highlight-bg-color     : $blue_bell;
$nav-mob-4th-highlight-text-color   : $primary;
$nav-mob-4th-line-color     				: purple;
$nav-mob-4th-border-color     			: $dark_lavender;

$nav-mob-border											: 1px solid $glitter;
$nav-mob-expanded-border						: 1px solid $glitter;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: $box-shadow;


/* MOBILE MENU TRIGGER */
$nav-button-bg-color						: $menu_green_tsl;
$nav-button-hover-bg-color			: $menu_green_tsl;
$nav-button-height      				: 88px;
$nav-button-width      					: 86px;
$nav-button-bar-bg-color      	: $granite_grey;
$nav-button-bar-hover-bg-color  : $light_seagreen;
$nav-button-bar-height      		: 4px;
$nav-button-bar-width      			: 46px;
$nav-button-bar-border-radius 	: 10px;
