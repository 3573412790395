// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #22333B; /* gun_metal */ 
$secondary         : #3E3E40; /* black_olive */ 


// /* MAIN PALETTE */ //
$dogwood_rose      : #D81E5B; /* dogwood_rose */ /// MOBILE MENU
$light_blue        : #84D0F5; /* lightblue */
$faded_blue        : #5D86C5; /* faded_blue */ /// MOBILE MENU
$middle_red_purple : #38023B; /* middle_red_purple */

$white_smoke       : #F5F6FB; /* more link alt-1/default bg */
$light_seagreen    : #1ABB9C; /* more link alt-3 bg */
$blue_yonder       : #5469BB; /* default card bg hover */
$glitter           : #E6E9F5; 
$rajah             : #FBC060; /* event date wrapper bg */
$soap              : #C9CFE9;

$anti-flash-white  : #F2F2F2; /* default / alt-1 palette bg */
$black_olive       : #3E3E40; /* alt-2 pallette bg */
$yellow_orange     : #FBB13C; /* highlight palette bg */
$gun_metal         : #22333B; /* breadcrumb/footer bg */

$emerald           : #148E77; /* title text */
$granite_grey      : #5F6062; /* text */

/* translucent hover bgs */

$yellow_tsl        : rgba(251, 177, 60, 0.3);
$white_tsl         : rgba(255, 255, 255, 0.6);
$green_tsl         : rgba(26, 187, 156, 0.3);
$blue_tsl          : rgba(84, 105, 187, 0.2);
$menu_blue_tsl     : rgba(84, 105, 187, 0.4);
$menu_green_tsl    : rgba(26, 187, 156, 0.4);
$menu_yellow_tsl   : rgba(251, 177, 60, 0.2);

// /* SEC PALETTE */ //
$pale_grey         : #f5f8fa; /* pale_grey */
$pale_grey_two     : #e4e8eb; /* pale_grey_two */
$battleship_grey   : #6b7280; /* battleship_grey */
$japanese_indigo   : #6b7280; /* japanese_indigo */
$platinum          : #e0e0e2; /* platinum */
$pearl_aqua        : #81D2C7; /* pearl_aqua */  /// MOBILE MENU
$dark_lavender     : #6250A1; /*  dark_lavender  */  /// MOBILE MENU
$columbia_blue     : #C9CEE6; /*  columbia_blue  */  /// MOBILE MENU

$black             : #000;
$grey              : #666;
$white             : #FFF;

// MENU

$jelly_bean					: #E46747;  /// MOBILE MENU

$middle_red					: #E28871;

$vivid_tanherine		: #EFA491;
$champagne_pink			: #F8D9D1;

$thulian_pink				: #E8789D;
$queen_pink					: #F5C7D6;

$pale_cerulean			: #9EB6DC;
$azureish_white			: #D6E1F0;

$blue_bell					: #A196C7;
$languid_lavander		: #D8D3E7;


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$gold_metallic       	: #DAAF44; /* jpg */
$teal_blue           	: #35618A; /* txt */
$jelly_bean          	: #D96C52; /* pptx */
$yonder_blue          : #577AA9; /* doc */
$middle_green        	: #4D8F6C; /* xlx */
$english_vermillion   : #CD4B39; /* pdf */
$jet                  : #333333; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */

$xlsx_size_color	 	  : $middle_green; /* xlsx */
$docx_size_color	 		: $yonder_blue; /* docx */
$jpg_size_color	 			: $gold_metallic; /* jpg */
$pdf_size_color	 			: $english_vermillion; /* pdf */
$pptx_size_color	 		: $jelly_bean; /* pptx */
$txt_size_color	 			: $teal_blue; /* txt */
$zip_size_color	 			: $jet; /* zip */


// /* LINKS */ //
$action-default      : $emerald;
$action-hover        : $yellow_orange;
$action-active       : $yellow_orange; 
$action-active-hover : $yellow_orange; 


// /* SHADOWS */ //
$box-shadow : none;


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #F3F3F3;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : $white_smoke;
$base-accent-color        : #477DCA;
$table-border-color       : #5F6062;
$table-cell-border        : 1px solid #5F6062;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $yellow_orange;
$table-header-border      : 1px solid darken($primary-border-color, 10%);
$table-hover-bg-color     : lighten($platinum, 10%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $secondary;
$form-box-shadow       : $box-shadow;
$form-box-shadow-focus : $box-shadow; 
$form-border-color     : $secondary-border-color;
$form-border           : 1px solid transparent;
$form-border-radius    : 0;

$button-bg             : $yellow_orange;
$button-hover-bg       : $emerald;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 0;
$button-hover-text     : white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey !default;