// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: transparent; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: $leaf-yellow-left no-repeat scroll 50% 50% / 48px 48px;
$slick-default-prev-bg-after              : $arrow-olive-left no-repeat scroll 50% 50% / 20px 20px;
$slick-default-prev-hover-bg      				: $leaf-sea-left no-repeat scroll 50% 50% / 48px 48px;
$slick-default-prev-hover-bg-after        : $arrow-white-left no-repeat scroll 50% 50% / 20px 20px;
$slick-default-next-bg            				: $leaf-yellow-right no-repeat scroll 50% 50% / 48px 48px;
$slick-default-next-bg-after              : $arrow-olive-right no-repeat scroll 50% 50% / 20px 20px;
$slick-default-next-hover-bg      				: $leaf-sea-right no-repeat scroll 50% 50% / 48px 48px;
$slick-default-next-hover-bg-after        : $arrow-white-right no-repeat scroll 50% 50% / 20px 20px;
$slick-default-dot-border         				: 1px solid $black_olive;
$slick-default-dot-hover-border    				: 1px solid $yellow_orange;
$slick-default-dot-hover-bg               : $yellow_orange;
$slick-default-dot-active-bg-color 				: $light_seagreen;
$slick-default-dot-active-border          : 1px solid $light_seagreen;
$slick-default-dot-active-hover-bg-color 	: $yellow_orange;


$slick-alt-1-nav-bg                				: transparent; // used on slide and galler views 
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: $leaf-sea-left no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-1-prev-bg-after                : $arrow-white-left no-repeat scroll 50% 50% / 20px 20px;
$slick-alt-1-prev-hover-bg         				: $leaf-emerald-left no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-1-prev-hover-bg-after          : $arrow-white-left no-repeat scroll 50% 50% / 20px 20px;
$slick-alt-1-next-bg               				: $leaf-sea-right no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-1-next-bg-after                : $arrow-white-right no-repeat scroll 50% 50% / 20px 20px;
$slick-alt-1-next-hover-bg         				: $leaf-emerald-right no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-1-next-hover-bg-after          : $arrow-white-right no-repeat scroll 50% 50% / 20px 20px;
$slick-alt-1-dot-default-border    				: 1px solid $black_olive;
$slick-alt-1-dot-hover-border      				: 1px solid white;
$slick-alt-1-dot-hover-bg                 : white;
$slick-alt-1-dot-active-bg-color   				: $light_seagreen;
$slick-alt-1-dot-active-border            : 1px solid $light_seagreen;
$slick-alt-1-dot-active-hover-bg-color 		: white;
$slick-alt-1-dot-active-hover-border      : 1px solid white;



$slick-alt-2-nav-bg                 			: transparent; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $leaf-yellow-left no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-2-prev-bg-after                : $arrow-olive-left no-repeat scroll 50% 50% / 20px 20px;
$slick-alt-2-prev-hover-bg         				: $leaf-sea-left no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-2-prev-hover-bg-after          : $arrow-white-left no-repeat scroll 50% 50% / 20px 20px;
$slick-alt-2-next-bg               				: $leaf-yellow-right no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-2-next-bg-after                : $arrow-olive-right no-repeat scroll 50% 50% / 20px 20px;
$slick-alt-2-next-hover-bg         				: $leaf-sea-right no-repeat scroll 50% 50% / 48px 48px;
$slick-alt-2-next-hover-bg-after          : $arrow-white-right no-repeat scroll 50% 50% / 20px 20px;
$slick-alt-2-dot-border    				        : 1px solid white;
$slick-alt-2-dot-hover-border      				: 1px solid $yellow_orange;
$slick-alt-2-dot-hover-bg                 : $yellow_orange;
$slick-alt-2-dot-active-bg-color   				: $light_seagreen;
$slick-alt-2-dot-active-border            : 1px solid $light_seagreen;
$slick-alt-2-dot-active-hover-bg-color 		: $yellow_orange;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : transparent ;
$slide-text-color          : white;
$slide-text-font           : 2rem/1.1 $title-font-family;

$slider-background         : $slider-bg no-repeat 50% 50% / 647px 608px;
$slider-background-yellow         : $slider-bg-yellow no-repeat 50% 50% / 647px 608px;
