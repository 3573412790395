.i18n-en .site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }
.i18n-fr .site-title a { background:$logo-fr no-repeat scroll 0 0 transparent; }
.i18n-pt .site-title a { background:$logo-pt no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
		&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.header-outer-wrapper {
		position:relative;
		@include outer-container($site-max-width);
	}
	.site-branding {
		//margin-top:2.5rem;
		margin-top:0;
		margin-left:1.25rem;
		position: relative;
		@include media(880px) {
			top:0;
		}
		.site-title {
			position:absolute;
			left:0;
			top:3.5rem;
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:240px;
				height:92px;
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}
	.wrapper-region-header {
    display: flex;
    float: right;
	}
	.block-search {
		float:right;
		height:10rem;
		@include media(880px) {
			height:auto;
		}
		h2 {
			font-family:$base-font-family;
			font-size:1rem;
			color:$primary;
			float:left;
			line-height:1.5;
			margin-top:1rem;
			margin-right:0.75rem;
			cursor:pointer;
			display:none;
			@include media(880px) {
				margin-right:2.5rem;
			}
		}
		.form-wrapper {
			display:none;
			@include media(880px) {
				display:block;
			}
		}
		.form-item-search-block-form {
			margin-top:0.75rem;
			//margin-top:0;
			margin-right:0.75rem;
			margin-bottom:0;
			@include media(880px) {
				margin-bottom:1rem;
			}
			@include media($wide) {
				margin-bottom:1rem;
			}
			float:left;
			input.form-text {
				width:10rem;
				height:1.875rem;
				background-color:$header-search-field-bg-color;
				color:$header-search-field-text-color;
				box-shadow:$header-search-field-box-shadow;
				border:$header-search-field-border;
				border-radius:$header-search-field-border-radius;
				font:$header-search-field-font;
				padding:0.375rem 0.75rem;
			}
		}
		.form-actions {
			float:left;
			margin-top:0.75rem;
			@include media(880px) {
				margin-bottom:5rem;
			}
			input.form-submit {
				background: transparent $search no-repeat scroll 0 0;
				background-size:14px;
				width:0.875rem;
				height:0.875rem;
				text-indent:-999em;
				overflow:hidden;
				padding:0;
				position:absolute;
				right:1.25rem;
				top:1.25rem;
			}
		}
	}
	.block-menu {
		float:right;
		//margin-top:1rem;
		margin-right:0.5rem;
		@include media(880px) {
			margin-right:1.5rem;
			margin-bottom:1rem;
		}
		li {
			display:inline-block;
			margin:0 0.75rem 0 0;
		}
		
		a {
			font:$header-menu-font;
			color:$header-menu-link-color;
			
			&:hover {
				color:$header-menu-link-hover;
			}
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
}